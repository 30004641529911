<template>
  <div>
    <!-- S:Bread crumbs -->
    <v-row>
      <v-col md="12" sm="12" lg="12" class="pa-10 pb-1">
        <v-card elevation="1" class="containerbox">
          <breadcrumbComp :mainPage="mainPage" :subPage="subPage" :backSlash="backSlash" :pageUrl="pageUrl"
            :showAdd="showAdd" @addFav="addFav()" />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" lg="12" class="pa-10 pt-0">
        <v-card elevation="1" class="containerbox">
          <v-card-text>
            <v-row>
              <v-col sm="4" md="4" lg="8"></v-col>
              <v-col sm="8" md="8" lg="4" class="float-right pa-0 ma-0 pt-4">
                <v-text-field class="searchbar mr-3" v-model="searchInstallers" append-icon="mdi-magnify" label="Search"
                  outlined clearable dense persistent-placeholder>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12" class="pa-0">
                <v-data-table id="datatable" :headers="installHeaders" :items="installers" class="pa-5 pt-0"
                  :search="searchInstallers">
                  <template v-slot:item.ActiveStatus="{ item }">
                    <span class="active" v-if="item.ActiveStatus == 'Active'">Active</span>
                    <span class="inactive" v-else>Inactive</span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import breadcrumbComp from '../common/breadcrumb-comp';
import Utility from '../../shared/utility'
import AddFavourite from "../utility/js/addFav"
import EncryptUtility from '../utility/js/encrypt-utility';
export default {
  data() {
    return {
      backSlash: true,
      mainPage: '',
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      installers: [],
      actionList: "",
      searchInstallers: [],
      userId: EncryptUtility.localStorageDecrypt('userID'),
      installHeaders: [
        {
          text: "Installer Code",
          align: "start",
          value: "ICode",
          class: "primary customwhite--text",
        },
        {
          text: "Installer Name",
          value: "IName",
          class: "primary customwhite--text",
        },
        {
          text: "Description",
          value: "Description",
          class: "primary customwhite--text",
        },
        {
          text: "Email",
          value: "IEmail",
          class: "primary customwhite--text",
        },
        {
          text: "Theme",
          value: "ThemeCode",
          class: "primary customwhite--text",
        },
        {
          text: "Status",
          value: "ActiveStatus",
          class: "primary customwhite--text",
        },
      ],
    }
  },
  async created() {
    this.route = this.$route.path.replace('/', '');
    let data = await AddFavourite.getPageAction(this.userId, this.route)
    this.actionList = data.actionList
    this.mainPage = data.mainPage
    this.favouriteList = data.favouriteList
    this.pageUrl = data.pageUrl
    this.subPage = data.subPage
    this.showAdd = data.showAdd
  },

  mounted() {
    this.getInstallerList();
  },
  methods: {
    //  on Click of Favorite icon
    addFav() {
      this.showAdd = !this.showAdd
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route)
    },
    // Getting Installer List
    getInstallerList() {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      this.axios
        .get('ap/install_list')
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.installers = responseData;
            const newArr = responseData.map((obj) => {
              return {
                ...obj,
                ActiveStatus: obj.IsActive == true ? 'Active' : 'Inactive'
              };
            });
            this.installers = newArr;
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            var Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          var Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },

  },
  components: {
    breadcrumbComp,
  },
}
</script>
<style scoped>
.active {
  background-color: #2ed8b6;
  border-radius: 5px;
  padding: 5px;
  color: white;
}

.inactive {
  background-color: #ff5370;
  border-radius: 5px;
  padding: 5px;
  color: white;
}
</style>